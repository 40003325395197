import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { IBEButton } from 'app/components/Common/Button/IBEButton'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Expand, User } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  id?: string
  IBEID?: number
  image?: ImageProps
  languageCode: string
  languagePrefix?: string | null
  mainService?: ServiceProps
  pax?: string
  size?: string
  title: string
  URL: string
}

export const Room = memo(function Room({
  id,
  IBEID,
  image,
  languageCode,
  languagePrefix,
  mainService,
  pax,
  size,
  title,
  URL,
}: Props) {
  if (!title || !URL) {
    return null
  }

  const [titleFirst, ...titleRest] = title.split(' ')

  return (
    <>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Container id={id} row stretch wrap>
          <LeftSide>
            <Image>
              {image ? <LazyLoadImage effect="blur" {...image} /> : null}
            </Image>
            {pax || size ? (
              <Media greaterThanOrEqual="ipadHorizontal">
                <Info dial={5} row>
                  {size ? (
                    <Size dial={4} row>
                      <Expand />
                      {size}
                    </Size>
                  ) : null}
                  {pax ? (
                    <Pax dial={4} row>
                      <User />
                      {pax}
                    </Pax>
                  ) : null}
                </Info>
              </Media>
            ) : null}
          </LeftSide>

          <RightSide dial={4}>
            <FadeInUp>
              <Wrap>
                <Label>{titleFirst}</Label>
                <Title>{titleRest.join(' ')}</Title>
                {pax || size ? (
                  <Media lessThan="ipadHorizontal">
                    <Info dial={5} row>
                      {size ? (
                        <Size dial={4} row>
                          <Expand />
                          {size}
                        </Size>
                      ) : null}
                      {pax ? (
                        <Pax dial={4} row>
                          <User />
                          {pax}
                        </Pax>
                      ) : null}
                    </Info>
                  </Media>
                ) : null}
              </Wrap>
            </FadeInUp>
            <FadeInUp>
              <MainService>
                {mainService ? <Service {...mainService} /> : null}
              </MainService>
            </FadeInUp>
            {IBEID ? (
              <FadeInUp>
                <IBEButton
                  IBEID={IBEID}
                  languageCode={languageCode}
                  languagePrefix={languagePrefix}
                />
              </FadeInUp>
            ) : null}
            <FadeInUp>
              <Details
                label={useVocabularyData('details', languageCode)}
                URL={URL}
                variant="outline"
              />
            </FadeInUp>
          </RightSide>
        </Container>
      </MediaContextProvider>
    </>
  )
})

const Container = styled(FlexBox)`
  min-height: 37.5rem;
  &:after {
    content: '';
    width: 100%;
    height: 12.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
    }
  }

  @media (max-width: 1023px) {
    min-height: 0;
    margin-bottom: 5.625rem;
    &:last-of-type {
      margin-top: 0;
    }
    &:after {
      display: none;
    }
  }
`

const LeftSide = styled.div`
  width: 42%;

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Image = styled.div`
  height: 25rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight1};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    height: auto;
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const Info = styled(FlexBox)`
  height: 12.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 2rem;
  padding: 0 3.333vw;

  > div {
    margin: 0 0.9375rem;
  }
  svg {
    width: 1.25rem;
    height: auto;
    max-height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 1rem;
    transform: translateY(-0.125rem);
  }

  @media (max-width: 1023px) {
    height: auto;
    background: none;
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 1.375rem;
    padding: 0;
    > div {
      margin: 0 1.5rem 0 0;
    }
    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }
`

const Size = styled(FlexBox)``

const Pax = styled(FlexBox)``

const RightSide = styled(FlexBox)`
  width: 58%;
  margin-top: 3rem;
  padding: 0 9.028vw;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    padding: 0 1.875rem;
  }
`

const Wrap = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding-left: 2.6875rem;
  position: relative;
  &:before {
    content: '';
    width: 0.1875rem;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding-left: 1.875rem;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 4.25rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.375rem;
  }
`

const MainService = styled.div`
  min-height: 2.75rem;
  margin: 2.75rem 0 4.5rem;

  @media (max-width: 1023px) {
    min-height: 0;
    margin: 2.5rem 0 3.75rem;
  }
`

const Details = styled(Button)`
  margin-top: 0.75rem;
`
