import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Filters } from './Filters'
import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      <Filters rooms={rooms} />

      {rooms.map((item, index) => {
        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        return <Room id={`room-${section}`} key={index} {...item} />
      })}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    margin-top: 0;
  }
`
