import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container dial={4} row>
      {icon ? <Icon src={icon} alt={label} width="30" height="30" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)``

const Icon = styled.img`
  width: auto;
  max-width: 1.875rem;
  height: 1.25rem;
  margin-right: 0.75rem;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
`
