import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Section } from 'app/components/Common/Section'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  services: string[]
  title?: string
}

export const RoomsServices = memo(function RoomsServices({
  languageCode,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      <Section label="comfort" languageCode={languageCode} />
      <Wrapper row stretch wrap>
        <LeftSide dial={4}>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
        </LeftSide>
        <RightSide>
          <FadeInUp>
            <Services row wrap>
              {services.map((item, index) => (
                <Service key={index}>
                  {
                    // @ts-ignore
                    item.label
                  }
                </Service>
              ))}
            </Services>
          </FadeInUp>
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 16.5625rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 12.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  text-align: left;
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding: 5rem 9.028vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3.75rem 1.875rem;
    text-align: center;
    > div {
      margin: auto;
    }
  }
`

const Title = styled.div`
  max-width: 18.75rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  letter-spacing: 0.1875rem;
  line-height: 1.6875rem;
  padding-left: 2.5rem;
  position: relative;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: -1.5rem;
    bottom: -1.5rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 1rem;
    line-height: 1.75rem;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  padding: 3.75rem 7.5vw 5.625rem;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.875rem 3.75rem;
  }
`

const Services = styled(FlexBox)``

const Service = styled.div`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
  margin-top: 1.25rem;
  padding-left: 2rem;
  position: relative;
  &:before {
    content: '';
    width: 1.25rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 1.0625rem;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`
