import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(rooms, 'title').map((item, index) => {
        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        const [titleFirst, ...titleRest] = item.title.split(' ')

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-139}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {titleRest.join(' ')}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 2.5rem 3.333vw;
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 2;
  &.fixed {
    animation: filtersFadeIn 0.3s;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    position: fixed;
    top: 5rem;
  }

  @keyframes filtersFadeIn {
    0% {
      top: 0;
    }
    100% {
      top: 5rem;
    }
  }

  @media (max-width: 1023px) {
    padding: 1.25rem 1.875rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Filter = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.0813rem;
  line-height: 1.1875rem;
  margin: 0 1.5rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
`
